import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoimage from '../assets/DF-LOGO1 (2).png'; // Adjust the path as needed
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate(); // Initialize navigate function

 
  return (
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row className="justify-content-center align-items-center text-start">
          {/* Logo and About Us Section */}
          <Col lg={6} xs={12} md={6} className="mb-4 mb-md-0 text-start">
            {/* <a href="/"  rel="noopener noreferrer">
              <img
                src={logoimage}
                alt="Logo"
                style={{ maxHeight: '70px' }} // Adjust logo size if needed
              />
            </a> */}

            <div className="mt-3">
              <h5>About Us</h5>
              <p style={{ color: '#FFFFFF', lineHeight: '1.6',textAlign: 'justify' }}>
              Discount Feast is a membership program committed to provide you a great discount experience in Food and other shopping.
Unlike other vouchers which you get after shopping and mostly useless in real sense, our membership program offers 
you a "Discount Deal" whenever "YOU" need it. Unique feature of our program is "No Cap" discounts and hassle-free Online
Coupons. Whatever discount is committed to you via "ONLINE COUPONS" you will get it on any amount of billing.
              </p>
            </div>
            <div className="d-flex justify-content-start social-icons mt-3">
              <a href=""   target="_blank" className="text-light mx-2">
                <FaTwitter size={20} />
              </a>
              <a href="https://www.facebook.com/people/Discount-Feast-India/61566595436502/?mibextid=qi2Omg&rdid=ipnWoMCroGgDTYGT&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FSxruKxXo9yH4FSsS%2F%3Fmibextid%3Dqi2Omg"   target="_blank"  className="text-light mx-2">
                <FaFacebook size={20} />
              </a>
              <a href="https://www.instagram.com/discount_feast/?igsh=MXAzZjR1dzVocnV2Yw%3D%3D"    target="_blank"  className="text-light mx-2">
                <FaInstagram size={20} />
              </a>
            </div>
          </Col>

          {/* Discover, About, and Legal Sections */}
          <Col lg={6}  xs={12} md={6} className="justify-content-center">
            <Row className="justify-content-center">
              {/* <Col xs={12} md={3} className=" mb-4 mb-md-0 text-start">
                <h5>Discover</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Bestsellers</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#FFFFFF' }}>Shop all</a>
                  </li>
                  <li className="mb-2">
                    <a href="#" style={{ textDecoration: 'none', color: '#D9534F' }}>% Sales</a>
                  </li>
                </ul>
              </Col> */}

              <Col xs={12} md={4} className="mb-4 mb-md-0 text-start">
                <h5>Company</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    {/* Using onClick instead of href */}
                    <span
                     onClick={() => navigate('/aboutus')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      About Us
                    </span>
                  </li>
                  <li className="mb-2">
                    <span
                     onClick={() => navigate('/')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      Help Center
                    </span>
                  </li>
                  <li className="mb-2">
                    <span
                      onClick={() => navigate('/')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      Affiliates
                    </span>
                  </li>
                </ul>
              </Col>

              <Col xs={12} md={4} className="mb-4 mb-md-0 text-start">
                <h5>Legal</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    {/* Using onClick instead of href */}
                    <span
                     onClick={() => navigate('/contactus')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      Contact
                    </span>
                  </li>
                  <li className="mb-2">
                    <span
                     onClick={() => navigate('/privacypolicy')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      Privacy Policies
                    </span>
                  </li>
                  <li className="mb-2">
                    <span
                      onClick={() => navigate('/termcondition')}
                      style={{ textDecoration: 'none', color: '#FFFFFF', cursor: 'pointer' }}
                    >
                      Terms & Conditions
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <Row className="mt-4 pt-2 text-center" style={{ borderTop: '1px solid white' }}>
          <Col>
            <small style={{ color: "white" }}>&copy;2024 Discount Feast. All Rights Reserved.</small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
