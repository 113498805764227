import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMobileAlt } from 'react-icons/fa';
import { MdLocationPin } from "react-icons/md";
import UserHeader from './UserHeader';
import Footer from './Footer';

const ContactUs = () => {
  return (
    <>
            <UserHeader />
    <Container className="py-5">
      <Row className="justify-content-center mb-4">
      
        <Col md={8} className="text-center">
          <h1>Contact Us</h1>
          <p>If you have any questions, feel free to reach out to us through the form below.</p>
        </Col>
      </Row>

      <Row className="justify-content-start align-items-center">
      <Col md={1}></Col>
        {/* Contact Information Section */}
        <Col md={4} className="text-center">
          <Row className="mb-4">
            <Col className='d-flex justify-content-start align-items-center'>
              <div style={{
                backgroundColor: '#EF4C0B',
                borderRadius: '50%',
                width: '70px',
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <MdLocationPin size={40} color="white" />
              </div>
              <div className="ms-3 text-start">
                <strong>Address</strong>
                <p>Prabhakar Prasad, A wing, First Floor,<br />1366 Sadashiv Peth, Bajirao Road,<br />Natubag, Pune 411030</p>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className='d-flex justify-content-start  align-items-center'>
              <div style={{
                backgroundColor: '#EF4C0B',
                borderRadius: '50%',
                width: '70px',
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <FaEnvelope size={30} color="white" />
              </div>
              <div className="ms-3 text-start">
                <strong>Email</strong>
                <p><a href="mailto:hello@discountfeast.co.in" style={{ color: 'black', textDecoration: 'none' }}>hello@discountfeast.co.in</a></p>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col className='d-flex justify-content-start  align-items-center'>
              <div style={{
                backgroundColor: '#EF4C0B',
                borderRadius: '50%',
                width: '70px',
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <FaPhone size={30} color="white" />
              </div>
              <div className="ms-3 text-start">
                <strong>Support Number</strong>
                <p><a href="tel:+918421029663" style={{ color: 'black', textDecoration: 'none' }}>+91 84210 29663</a></p>
              </div>
            </Col>
          </Row>
        </Col>
<Col md={1}></Col>
        {/* Get in Touch Form */}
        <Col md={6}>
          <h2>Get in Touch</h2>
          <Form>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>

            <Form.Group controlId="message" className="mb-4">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={5} placeholder="Write your message" />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100" style={{backgroundColor:'#EF4C0B', border:'none'}}>
              Submit
            </Button>
          </Form>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="text-center">
         {/* Google Map Embed */}
         <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.4191559576907!2d73.85164551068915!3d18.50995128250887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07275994935%3A0x84f9a07f42bd7851!2sPrabhakar%20Prasad%20Tower%2C%20Guruvarya%20LG%20Deshpande%20Rd%2C%20Perugate%2C%20Sadashiv%20Peth%2C%20Pune%2C%20Maharashtra%20411030!5e0!3m2!1sen!2sin!4v1727682217353!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
    </Container>
    
    <Footer />
    </>
  );
};

export default ContactUs;
