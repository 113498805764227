// // import React, { useState } from 'react';
// // import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// // import axios from 'axios';  
// // import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
// // import '../CSS/member.css';
// // import Footer from './Footer';
// // import UserHeader from './UserHeader';
// // import Pay from './Pay';

// // export default function RequestForMembership() {
// //     const navigate = useNavigate();  // Initialize the navigate hook

// //     // State for form fields and screenshot
// //     const [formData, setFormData] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //     });

// //     const [screenshot, setScreenshot] = useState(null); // For storing the screenshot

// //     // State for validation errors
// //     const [errors, setErrors] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //     });

// //     // Handle input change for form fields
// //     const handleChange = (e) => {
// //         const { name, value } = e.target;
// //         setFormData({ ...formData, [name]: value });
// //     };

// //     // Handle file change for screenshot
// //     const handleFileChange = (e) => {
// //         setScreenshot(e.target.files[0]); // Store the selected file in state
// //     };

// //     // Validate form data
// //     const validate = () => {
// //         const newErrors = {};
// //         if (!formData.name) newErrors.name = 'Name is required';
// //         if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
// //         if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
// //         if (!formData.address) newErrors.address = 'Address is required';
// //         setErrors(newErrors);
// //         return Object.keys(newErrors).length === 0;
// //     };

// //     // Handle form submit
// //     const handleSubmit = async (e) => {
// //         e.preventDefault();  // Prevent the default form submission behavior

// //         if (validate()) {
// //             // Create FormData object to include screenshot
// //             const formDataToSend = new FormData();
// //             formDataToSend.append('name', formData.name);
// //             formDataToSend.append('email', formData.email);
// //             formDataToSend.append('phoneNumber', formData.phoneNumber);
// //             formDataToSend.append('address', formData.address);
// //             if (screenshot) {
// //                 formDataToSend.append('screenshot', screenshot); // Append screenshot file
// //             }

// //             try {
// //                 const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
// //                     headers: {
// //                         'Content-Type': 'multipart/form-data', // Set header for file upload
// //                     },
// //                 });
// //                 console.log('Form submitted successfully:', response.data);
// //                 // Redirect the user after successful form submission
// //                 navigate('/');  // Redirect to a different path after submission
// //             } catch (error) {
// //                 console.error('Error submitting form:', error);
// //                 // Handle error (e.g., show an error message)
// //             }
// //         }
// //     };

// //     return (
// //         <Container fluid className=" justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
// //             <UserHeader />
// //             <Row className="w-100  bgimage2 m-0" style={{ padding: '20px' }}>
// //                 <h2 className="signuph2 pt-4 text-center">Request for Membership</h2>
// //                 {/* Form Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="  justify-content-center pt-2 m-0 bgimage2 p-0">
// //                     <div className="content-wrapper justify-content-center w-100">
// //                         <div className="form-container " style={{ padding: '0 0' }}>
// //                             <Form onSubmit={handleSubmit} noValidate>
// //                                 {/* Name Field */}
// //                                 <Row className=" justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="name" className="mb-2">
// //                                             <Form.Label>Full Name</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="Name"
// //                                                 name="name"
// //                                                 value={formData.name}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.name}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Email Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="email" className="mb-2">
// //                                             <Form.Label>Email</Form.Label>
// //                                             <Form.Control
// //                                                 type="email"
// //                                                 placeholder="Email"
// //                                                 name="email"
// //                                                 value={formData.email}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.email}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Phone Number Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="phoneNumber" className="mb-2">
// //                                             <Form.Label>Phone Number</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="1234567890"
// //                                                 name="phoneNumber"
// //                                                 value={formData.phoneNumber}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.phoneNumber}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Address Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="address" className="mb-2">
// //                                             <Form.Label>Address</Form.Label>
// //                                             <Form.Control
// //                                                 as="textarea"
// //                                                 placeholder="Address"
// //                                                 name="address"
// //                                                 value={formData.address}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.address}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Screenshot Upload Section */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="screenshot" className="mb-2">
// //                                             <Form.Label>Upload Payment Screenshot</Form.Label>
// //                                             <Form.Control
// //                                                 type="file"
// //                                                 name="screenshot"
// //                                                 accept="image/*"
// //                                                 onChange={handleFileChange}
// //                                             />
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Submit Button */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={6} md={8} sm={8} xs={8}>
// //                                         <Form.Group controlId="addMember" className="otpbtn mb-2">
// //                                             <Button variant="warning" type="submit" className="w-100" style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}>
// //                                                 Request For Membership
// //                                             </Button>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>
// //                             </Form>
// //                         </div>
// //                     </div>
// //                 </Col>

// //                 {/* Pay Component Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="pay-section  bgimage1 p-0 d-flex align-items-center justify-content-start p-0">
// //                     <Pay />
// //                 </Col>
// //             </Row>
// //             <Footer />
// //         </Container>
// //     );
// // }


// // import React, { useState } from 'react';
// // import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// // import axios from 'axios';  
// // import { useNavigate } from 'react-router-dom';  
// // import '../CSS/member.css';
// // import Footer from './Footer';
// // import UserHeader from './UserHeader';
// // import Pay from './Pay';

// // export default function RequestForMembership() {
// //     const navigate = useNavigate();  

// //     const [formData, setFormData] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //     });

// //     const [screenshot, setScreenshot] = useState(null); 
// //     const [errors, setErrors] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //     });

// //     // State for success and error messages
// //     const [successMessage, setSuccessMessage] = useState('');
// //     const [errorMessage, setErrorMessage] = useState('');

// //     const handleChange = (e) => {
// //         const { name, value } = e.target;
// //         setFormData({ ...formData, [name]: value });
// //     };

// //     const handleFileChange = (e) => {
// //         setScreenshot(e.target.files[0]); 
// //     };

// //     const validate = () => {
// //         const newErrors = {};
// //         if (!formData.name) newErrors.name = 'Name is required';
// //         if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
// //         if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
// //         if (!formData.address) newErrors.address = 'Address is required';
// //         setErrors(newErrors);
// //         return Object.keys(newErrors).length === 0;
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();  

// //         if (validate()) {
// //             const formDataToSend = new FormData();
// //             formDataToSend.append('name', formData.name);
// //             formDataToSend.append('email', formData.email);
// //             formDataToSend.append('phoneNumber', formData.phoneNumber);
// //             formDataToSend.append('address', formData.address);
// //             if (screenshot) {
// //                 formDataToSend.append('screenshot', screenshot); 
// //             }

// //             try {
// //                 const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
// //                     headers: {
// //                         'Content-Type': 'multipart/form-data',
// //                     },
// //                 });
// //                 console.log('Form submitted successfully:', response.data);
                
// //                 setSuccessMessage('Your request was submitted successfully!');
// //                 setErrorMessage('');  // Clear any previous error messages
                
// //                 // Redirect the user after a short delay
// //                 setTimeout(() => {
// //                     navigate('/');  
// //                 }, 2000);  // Redirect after 2 seconds
// //             } catch (error) {
// //                 console.error('Error submitting form:', error);
// //                 setErrorMessage('There was an error submitting the form. Please try again.');
// //                 setSuccessMessage('');  // Clear any previous success messages
// //             }
// //         }
// //     };

// //     return (
// //         <Container fluid className=" justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
// //             <UserHeader />
// //             <Row className="w-100  bgimage2 m-0" style={{ padding: '20px' }}>
// //                 <h2 className="signuph2 pt-4 text-center">Request for Membership</h2>

// //                 {/* Alerts Section */}
// //                 <Col lg={12} className="text-center">
// //                     {successMessage && <Alert variant="success">{successMessage}</Alert>}
// //                     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
// //                 </Col>

// //                 {/* Form Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="justify-content-center pt-2 m-0 bgimage2 p-0">
// //                     <div className="content-wrapper justify-content-center w-100">
// //                         <div className="form-container" style={{ padding: '0 0' }}>
// //                             <Form onSubmit={handleSubmit} noValidate>
// //                                 {/* Name Field */}
// //                                 <Row className="justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="name" className="mb-2">
// //                                             <Form.Label>Full Name</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="Name"
// //                                                 name="name"
// //                                                 value={formData.name}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.name}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Email Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="email" className="mb-2">
// //                                             <Form.Label>Email</Form.Label>
// //                                             <Form.Control
// //                                                 type="email"
// //                                                 placeholder="Email"
// //                                                 name="email"
// //                                                 value={formData.email}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.email}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Phone Number Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="phoneNumber" className="mb-2">
// //                                             <Form.Label>Phone Number</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="1234567890"
// //                                                 name="phoneNumber"
// //                                                 value={formData.phoneNumber}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.phoneNumber}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Address Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="address" className="mb-2">
// //                                             <Form.Label>Address</Form.Label>
// //                                             <Form.Control
// //                                                 as="textarea"
// //                                                 placeholder="Address"
// //                                                 name="address"
// //                                                 value={formData.address}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.address}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Screenshot Upload Section */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="screenshot" className="mb-2">
// //                                             <Form.Label>Upload Payment Screenshot</Form.Label>
// //                                             <Form.Control
// //                                                 type="file"
// //                                                 name="screenshot"
// //                                                 accept="image/*"
// //                                                 onChange={handleFileChange}
// //                                             />
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Submit Button */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={6} md={8} sm={8} xs={8}>
// //                                         <Form.Group controlId="addMember" className="otpbtn mb-2">
// //                                             <Button variant="warning" type="submit" className="w-100" style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}>
// //                                                 Request For Membership
// //                                             </Button>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>
// //                             </Form>
// //                         </div>
// //                     </div>
// //                 </Col>

// //                 {/* Pay Component Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="pay-section bgimage1 p-0 d-flex align-items-center justify-content-start p-0">
// //                     <Pay />
// //                 </Col>
// //             </Row>
// //             <Footer />
// //         </Container>
// //     );
// // }


// // import React, { useState } from 'react';
// // import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// // import axios from 'axios';  
// // import { useNavigate } from 'react-router-dom';  
// // import '../CSS/member.css';
// // import Footer from './Footer';
// // import UserHeader from './UserHeader';
// // import Pay from './Pay';

// // export default function RequestForMembership() {
// //     const navigate = useNavigate();  

// //     const [formData, setFormData] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //     });

// //     const [screenshot, setScreenshot] = useState(null); 
// //     const [errors, setErrors] = useState({
// //         name: '',
// //         email: '',
// //         phoneNumber: '',
// //         address: '',
// //         screenshot: '',  // Add error state for screenshot
// //     });

// //     // State for success and error messages
// //     const [successMessage, setSuccessMessage] = useState('');
// //     const [errorMessage, setErrorMessage] = useState('');

// //     const handleChange = (e) => {
// //         const { name, value } = e.target;
// //         setFormData({ ...formData, [name]: value });
// //     };

// //     const handleFileChange = (e) => {
// //         setScreenshot(e.target.files[0]); 
// //     };

// //     const validate = () => {
// //         const newErrors = {};
// //         if (!formData.name) newErrors.name = 'Name is required';
// //         if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
// //         if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
// //         if (!formData.address) newErrors.address = 'Address is required';
// //         if (!screenshot) newErrors.screenshot = 'Payment Receipt is required';  // Check if screenshot is uploaded
// //         setErrors(newErrors);
// //         return Object.keys(newErrors).length === 0;
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();  

// //         if (validate()) {
// //             const formDataToSend = new FormData();
// //             formDataToSend.append('name', formData.name);
// //             formDataToSend.append('email', formData.email);
// //             formDataToSend.append('phoneNumber', formData.phoneNumber);
// //             formDataToSend.append('address', formData.address);
// //             if (screenshot) {
// //                 formDataToSend.append('screenshot', screenshot); 
// //             }

// //             try {
// //                 const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
// //                     headers: {
// //                         'Content-Type': 'multipart/form-data',
// //                     },
// //                 });
// //                 console.log('Form submitted successfully:', response.data);
                
// //                 setSuccessMessage('Your request was submitted successfully!');
// //                 setErrorMessage('');  // Clear any previous error messages
                
// //                 // Redirect the user after a short delay
// //                 setTimeout(() => {
// //                     navigate('/');  
// //                 }, 2000);  // Redirect after 2 seconds
// //             } catch (error) {
// //                 console.error('Error submitting form:', error);
// //                 setErrorMessage('There was an error submitting the form. Please try again.');
// //                 setSuccessMessage('');  // Clear any previous success messages
// //             }
// //         }
// //     };

// //     return (
// //         <Container fluid className=" justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
// //             <UserHeader />
// //             <Row className="w-100 bgimage2 m-0" style={{ padding: '20px' }}>
// //                 <h2 className="signuph2 pt-4 text-center">Request for Membership</h2>

// //                 {/* Alerts Section */}
// //                 <Col lg={12} className="text-center">
// //                     {successMessage && <Alert variant="success">{successMessage}</Alert>}
// //                     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
// //                 </Col>

// //                 {/* Form Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="justify-content-center pt-2 m-0 bgimage2 p-0">
// //                     <div className="content-wrapper justify-content-center w-100">
// //                         <div className="form-container" style={{ padding: '0 0' }}>
// //                             <Form onSubmit={handleSubmit} noValidate>
// //                                 {/* Name Field */}
// //                                 <Row className="justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="name" className="mb-2">
// //                                             <Form.Label>Full Name</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="Name"
// //                                                 name="name"
// //                                                 value={formData.name}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.name}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Email Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="email" className="mb-2">
// //                                             <Form.Label>Email</Form.Label>
// //                                             <Form.Control
// //                                                 type="email"
// //                                                 placeholder="Email"
// //                                                 name="email"
// //                                                 value={formData.email}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.email}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Phone Number Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="phoneNumber" className="mb-2">
// //                                             <Form.Label>Phone Number</Form.Label>
// //                                             <Form.Control
// //                                                 type="text"
// //                                                 placeholder="1234567890"
// //                                                 name="phoneNumber"
// //                                                 value={formData.phoneNumber}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.phoneNumber}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Address Field */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="address" className="mb-2">
// //                                             <Form.Label>Address</Form.Label>
// //                                             <Form.Control
// //                                                 as="textarea"
// //                                                 placeholder="Address"
// //                                                 name="address"
// //                                                 value={formData.address}
// //                                                 onChange={handleChange}
// //                                                 isInvalid={!!errors.address}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Screenshot Upload Section */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={8} md={8} sm={8} xs={12}>
// //                                         <Form.Group controlId="screenshot" className="mb-2">
// //                                             <Form.Label>Upload Payment Receipt</Form.Label>
// //                                             <Form.Control
// //                                                 type="file"
// //                                                 name="screenshot"
// //                                                 accept="image/*"
// //                                                 onChange={handleFileChange}
// //                                                 isInvalid={!!errors.screenshot}
// //                                             />
// //                                             <Form.Control.Feedback type="invalid">{errors.screenshot}</Form.Control.Feedback>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>

// //                                 {/* Submit Button */}
// //                                 <Row className="align-items-center justify-content-center">
// //                                     <Col lg={6} md={8} sm={8} xs={8}>
// //                                         <Form.Group controlId="addMember" className="otpbtn mb-2">
// //                                             <Button variant="warning" type="submit" className="w-100" style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}>
// //                                                 Request For Membership
// //                                             </Button>
// //                                         </Form.Group>
// //                                     </Col>
// //                                 </Row>
// //                             </Form>
// //                         </div>
// //                     </div>
// //                 </Col>

// //                 {/* Pay Component Section */}
// //                 <Col lg={5} md={12} sm={12} xs={12} className="pay-section bgimage1 p-0 d-flex align-items-center justify-content-start p-0">
// //                     <Pay />
// //                 </Col>
// //             </Row>
// //             <Footer />
// //         </Container>
// //     );
// // }

// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';  
// import { useNavigate } from 'react-router-dom';  
// import '../CSS/member.css';
// import Footer from './Footer';
// import UserHeader from './UserHeader';
// import Pay from './Pay';

// export default function RequestForMembership() {
//     const navigate = useNavigate();  

//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phoneNumber: '',
//         address: '',
//     });

//     const [screenshot, setScreenshot] = useState(null); 
//     const [errors, setErrors] = useState({
//         name: '',
//         email: '',
//         phoneNumber: '',
//         address: '',
//         screenshot: '', 
//     });

//     const [successMessage, setSuccessMessage] = useState('');  // For success alert
//     const [errorMessage, setErrorMessage] = useState('');      // For error alert

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleFileChange = (e) => {
//         setScreenshot(e.target.files[0]); 
//     };

//     const validate = () => {
//         const newErrors = {};
//         if (!formData.name) newErrors.name = 'Name is required';
//         if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
//         if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
//         if (!formData.address) newErrors.address = 'Address is required';
//         if (!screenshot) newErrors.screenshot = 'Payment Receipt is required';
//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Clear any previous messages before submitting
//         setSuccessMessage('');
//         setErrorMessage('');

//         if (validate()) {
//             const formDataToSend = new FormData();
//             formDataToSend.append('name', formData.name);
//             formDataToSend.append('email', formData.email);
//             formDataToSend.append('phoneNumber', formData.phoneNumber);
//             formDataToSend.append('address', formData.address);
//             if (screenshot) {
//                 formDataToSend.append('screenshot', screenshot); 
//             }

//             try {
//                 const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 });
                
//                 // On success, set success message
//                 setSuccessMessage('Your request was submitted successfully!');
//                 setErrorMessage('');  // Clear any previous error messages
                
//                 // Redirect after 2 seconds
//                 setTimeout(() => {
//                     navigate('/');  
//                 }, 2000);
//             } catch (error) {
//                 // On error, set error message
//                 setErrorMessage('There was an error submitting the form. Please try again.');
//                 setSuccessMessage('');  // Clear any previous success messages
//             }
//         }
//     };

//     return (
//         <Container fluid className="justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
//             <UserHeader />
//             <Row className="w-100 bgimage2 m-0" style={{ padding: '20px' }}>
//                 <h2 className="signuph2 pt-4 text-center">Request for Membership</h2>

//                 {/* Alerts Section */}
//                 <Col lg={12} className="text-center">
//                     {successMessage && <Alert variant="success">{successMessage}</Alert>}
//                     {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
//                 </Col>

//                 {/* Form Section */}
//                 <Col lg={5} md={12} sm={12} xs={12} className="justify-content-center pt-2 m-0 bgimage2 p-0">
//                     <div className="content-wrapper justify-content-center w-100">
//                         <div className="form-container" style={{ padding: '0 0' }}>
//                             <Form onSubmit={handleSubmit} noValidate>
//                                 {/* Name Field */}
//                                 <Row className="justify-content-center">
//                                     <Col lg={8} md={8} sm={8} xs={12}>
//                                         <Form.Group controlId="name" className="mb-2">
//                                             <Form.Label>Full Name</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 placeholder="Name"
//                                                 name="name"
//                                                 value={formData.name}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.name}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Email Field */}
//                                 <Row className="align-items-center justify-content-center">
//                                     <Col lg={8} md={8} sm={8} xs={12}>
//                                         <Form.Group controlId="email" className="mb-2">
//                                             <Form.Label>Email</Form.Label>
//                                             <Form.Control
//                                                 type="email"
//                                                 placeholder="Email"
//                                                 name="email"
//                                                 value={formData.email}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.email}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Phone Number Field */}
//                                 <Row className="align-items-center justify-content-center">
//                                     <Col lg={8} md={8} sm={8} xs={12}>
//                                         <Form.Group controlId="phoneNumber" className="mb-2">
//                                             <Form.Label>Phone Number</Form.Label>
//                                             <Form.Control
//                                                 type="text"
//                                                 placeholder="1234567890"
//                                                 name="phoneNumber"
//                                                 value={formData.phoneNumber}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.phoneNumber}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Address Field */}
//                                 <Row className="align-items-center justify-content-center">
//                                     <Col lg={8} md={8} sm={8} xs={12}>
//                                         <Form.Group controlId="address" className="mb-2">
//                                             <Form.Label>Address</Form.Label>
//                                             <Form.Control
//                                                 as="textarea"
//                                                 placeholder="Address"
//                                                 name="address"
//                                                 value={formData.address}
//                                                 onChange={handleChange}
//                                                 isInvalid={!!errors.address}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Screenshot Upload Section */}
//                                 <Row className="align-items-center justify-content-center">
//                                     <Col lg={8} md={8} sm={8} xs={12}>
//                                         <Form.Group controlId="screenshot" className="mb-2">
//                                             <Form.Label>Upload Payment Receipt</Form.Label>
//                                             <Form.Control
//                                                 type="file"
//                                                 name="screenshot"
//                                                 accept="image/*"
//                                                 onChange={handleFileChange}
//                                                 isInvalid={!!errors.screenshot}
//                                             />
//                                             <Form.Control.Feedback type="invalid">{errors.screenshot}</Form.Control.Feedback>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>

//                                 {/* Submit Button */}
//                                 <Row className="align-items-center justify-content-center">
//                                     <Col lg={6} md={8} sm={8} xs={8}>
//                                         <Form.Group controlId="addMember" className="otpbtn mb-2">
//                                             <Button variant="warning" type="submit" className="w-100" style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}>
//                                                 Request For Membership
//                                             </Button>
//                                         </Form.Group>
//                                     </Col>
//                                 </Row>
//                             </Form>
//                         </div>
//                     </div>
//                 </Col>

//                 {/* Pay Component Section */}
//                 <Col lg={5} md={12} sm={12} xs={12} className="pay-section bgimage1 p-0 d-flex align-items-center justify-content-start p-0">
//                     <Pay />
//                 </Col>
//             </Row>
//             <Footer />
//         </Container>
//     );
// }


import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';  
import { useNavigate } from 'react-router-dom';  
import '../CSS/member.css';
import Footer from './Footer';
import UserHeader from './UserHeader';
import Pay from './Pay';

export default function RequestForMembership() {
    const navigate = useNavigate();  

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
    });

    const [screenshot, setScreenshot] = useState(null); 
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        address: '',
        screenshot: '', 
    });

    const [successMessage, setSuccessMessage] = useState('');  // For success alert
    const [errorMessage, setErrorMessage] = useState('');      // For error alert

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]); 
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phoneNumber || !/^\+?\d{10,15}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Valid phone number is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!screenshot) newErrors.screenshot = 'Payment Receipt is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear any previous messages before submitting
        setSuccessMessage('');
        setErrorMessage('');

        if (validate()) {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phoneNumber', formData.phoneNumber);
            formDataToSend.append('address', formData.address);
            if (screenshot) {
                formDataToSend.append('screenshot', screenshot); 
            }

            try {
                const response = await axios.post('https://discountfeast.co.in/requestnew/requestmember', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Send email notification after successful submission
                await axios.post('https://discountfeast.co.in/emailrequest/sendEmail', {
                    name: formData.name,
                    email: formData.email,
                    phoneNumber: formData.phoneNumber,
                    address: formData.address,
                });

                // On success, set success message
                setSuccessMessage('Your request was submitted successfully! An email has been sent.');
                setErrorMessage('');  // Clear any previous error messages
                
                // Redirect after 2 seconds
                setTimeout(() => {
                    navigate('/');  
                }, 2000);
            } catch (error) {
                // On error, set error message
                setErrorMessage('There was an error submitting the form or sending the email. Please try again.');
                setSuccessMessage('');  // Clear any previous success messages
            }
        }
    };

    return (
        <Container fluid className="justify-content-center p-0 w-100" style={{ maxWidth: '100%' }}>
            <UserHeader />
            <Row className="w-100 bgimage2 m-0" style={{ padding: '20px' }}>
                <h2 className="signuph2 pt-4 text-center">Request for Membership</h2>

                {/* Alerts Section */}
                <Col lg={12} className="text-center">
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </Col>

                {/* Form Section */}
                <Col lg={5} md={12} sm={12} xs={12} className="justify-content-center pt-2 m-0 bgimage2 p-0">
                    <div className="content-wrapper justify-content-center w-100">
                        <div className="form-container" style={{ padding: '0 0' }}>
                            <Form onSubmit={handleSubmit} noValidate>
                                {/* Name Field */}
                                <Row className="justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="name" className="mb-2">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Email Field */}
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="email" className="mb-2">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Phone Number Field */}
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="phoneNumber" className="mb-2">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="1234567890"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phoneNumber}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Address Field */}
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="address" className="mb-2">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                isInvalid={!!errors.address}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Screenshot Upload Section */}
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={8} md={8} sm={8} xs={12}>
                                        <Form.Group controlId="screenshot" className="mb-2">
                                            <Form.Label>Upload Payment Receipt</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="screenshot"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                isInvalid={!!errors.screenshot}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.screenshot}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Submit Button */}
                                <Row className="align-items-center justify-content-center">
                                    <Col lg={6} md={8} sm={8} xs={8}>
                                        <Form.Group controlId="addMember" className="otpbtn mb-2">
                                            <Button variant="warning" type="submit" className="w-100" style={{ backgroundColor: '#EF4C0B', color: 'white', border: 'none' }}>
                                                Request For Membership
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>

                {/* Pay Component Section */}
                <Col lg={5} md={12} sm={12} xs={12} className="pay-section bgimage1 p-0 d-flex align-items-center justify-content-start p-0">
                    <Pay />
                </Col>
            </Row>
            <Footer />
        </Container>
    );
}
