// import React, { useState, useRef, useEffect } from 'react';
// import { auth } from '../firebase.config';
// import { useNavigate } from 'react-router-dom';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import UserHeader from './UserHeader';
// import Footer from './Footer';
// import '../CSS/termandcondition.css'

// export default function TermCondition() {
//     const navigate = useNavigate();

//     useEffect(() => {
//         const unsubscribe = auth.onAuthStateChanged((user) => {
//           if (user) {
//             // User is signed in, navigate to the dashboard
//             navigate('/termcondition'); // Adjust the route as needed
//           } else {
//             // No user is signed in, navigate to the login page
//             navigate('/termcondition');
//           }
//         });

//         // Cleanup subscription on unmount
//         return () => unsubscribe();
//       }, [navigate]);
//     return (
//         <>
//             <UserHeader />

//             <Container className="mt-4 mb-5">
//                 <Row className="justify-content-center">
//                     <Col md={10}>
//                         <Card className=" p-4" style={{ border: 'none' }}>
//                             <Card.Body>
//                                 <h2 className="text-center mb-5">Terms and Conditions</h2>

//                                 {/* <section className="terms-section">
//                                     <h5>Last Updated: 25/09/2024</h5>
//                                     <p>
//                                         Welcome to Discount Feast by Trekdi Tours DMC (OPC) Private Limited ! These Terms and Conditions govern your use of our website,<a href='www.discountfeast.co.in'>www.discountfeast.co.in</a> , and the services we provide. By accessing our site and using our services, you agree to comply with these terms.
//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>1. Acceptance of Terms</h5>
//                                     <p>
//                                         By accessing and using our services, you confirm that you accept these Terms and agree to abide by them. If you do not agree with any part of these terms, please do not use our site.

//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>2. Coupon Policy</h5>
//                                     <p>
//                                         No Return, Transfer, or Exchange: Coupons are non-returnable, non-transferable, and cannot be exchanged for cash or any kind.
//                                         Loyalty Points: Coupons cannot be converted into loyalty points, coins, or NFTs of any kind.
//                                         Redemption: Coupons must be redeemed before entering the premises and placing the order. Discounts will be refused if the person bearing the coupon is not present.
//                                         Identification: Users must carry a government-issued proof of identification when redeeming coupons.
//                                         Validity: Coupons are valid for redemption for 24 hours once claimed.
//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>3. Liability Disclaimer</h5>
//                                     <p>
//                                         In cases where the vendor or hotel is unable to fulfill the service, Discount Feast & Trekdi Tours DMC (OPC) Private Limited  will not be liable to offer any refunds or compensation.
//                                         Discount Feast & Trekdi Tours DMC (OPC) Private Limited  is not responsible for the service quality offered by the merchant or hotel.
//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>4. Data Usage and Privacy</h5>
//                                     <p>
//                                         User Data: Discount Feast & Trekdi Tours DMC (OPC) Private Limited  does not sell customer data. User data may be used for promotional purposes by Discount Feast. Users can opt out of promotional communications at any time.
//                                         Users have the right to request the deletion of their data. To unsubscribe from news, updates, promotions, or to request data deletion, please contact us at <a href='hello@discountfeast.co.in.'>hello@discountfeast.co.in.</a>
//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>5. Payment Gateway</h5>
//                                     <p>
//                                         All payment-related compliances and operations for Discount Feast are managed by "Trekdi Tours DMC (OPC) Private Limited" . Discount Feast uses third-party payment gateway services and shall not be liable for any failure of the payment service or issues arising from payment failures, including incomplete payments, payments on hold, or payment rejections.

//                                     </p>
//                                 </section>

//                                 <section className="terms-section">
//                                     <h5>6. Intellectual Property Rights</h5>
//                                     <p>
//                                         Usage of Logo and Branding: All logos, trademarks, and branding associated with Discount Feast are the exclusive property of Discount Feast and its parent company, Trekdi Tours DMC (OPC) Private Limited . Unauthorized use of these elements is strictly prohibited. Any requests for usage must be submitted in writing for consideration.
//                                     </p>
//                                 </section>
//                                 <section className="terms-section">
//                                     <h5>7. Modifications to Terms</h5>
//                                     <p>
//                                         Discount Feast & Trekdi Tours DMC (OPC) Private Limited reserves the right to modify these Terms and Conditions at any time. Changes will be posted on this page, and your continued use of the site after any changes constitutes acceptance of the new terms.

//                                     </p>
//                                 </section>
//                                 <section className="terms-section">
//                                     <h5>8. Governing Law</h5>
//                                     <p>
//                                         These Terms and Conditions are governed by the laws of Pune, Maharashtra. Any disputes arising from these terms will be handled in accordance with local laws.

//                                     </p>
//                                 </section>
//                                 <section className="terms-section">
//                                     <h5>9. Contact Information</h5>
//                                     <p>
//                                         For any questions regarding these Terms and Conditions, please contact us at <a href='hello@discountfeast.co.in.'>hello@discountfeast.co.in.</a>
//                                     </p>
//                                 </section> */}


// <div className="terms-container">
//             <h1>Terms & Conditions</h1>
//            <ol>
//             <p>
//                 This document is an electronic record in terms of Information Technology Act, 2000 and rules
//                 there under as applicable and the amended provisions pertaining to electronic records in various
//                 statutes as amended by the Information Technology Act, 2000. This electronic record is generated
//                 by a computer system and does not require any physical or digital signatures.
//             </p>
//             <p>
//                 This document is published in accordance with the provisions of Rule 3 (1) of the Information
//                 Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
//                 regulations, privacy policy and Terms of Use for access or usage of domain name discountfeast.co
//                 in ('Website'), including the related mobile site and mobile application (hereinafter referred to as
//                 'Platform').
//             </p>
//             <p>
//                 The Platform is owned by CAMIOS HOSPITALITY P, a company incorporated under the
//                 Companies Act, 1956 with its registered office at Flat 607 Bldg E Gloria Grace Sno
//                 474A474B47111216, PUNE, Pune Maharashtra, India, 411021, Pune, India (hereinafter
//                 referred to as ‘Platform Owner’, 'we', 'us', 'our').
//             </p>
//             <p>
//                 Your use of the Platform and services and tools are governed by the following terms and
//                 conditions (“Terms of Use”) as applicable to the Platform including the applicable policies which
//                 are incorporated herein by way of reference. If You transact on the Platform, You shall be subject
//                 to the policies that are applicable to the Platform for such transaction. By mere use of the Platform,
//                 You shall be contracting with the Platform Owner and these terms and conditions including the
//                 policies constitute Your binding obligations, with Platform Owner. These Terms of Use relate to
//                 your use of our website, goods (as applicable) or services (as applicable) (collectively, 'Services').
//             </p>
//             <p>
//                 Any terms and conditions proposed by You which are in addition to or which conflict with these
//                 Terms of Use are expressly rejected by the Platform Owner and shall be of no force or effect.
//                 These Terms of Use can be modified at any time without assigning any reason. It is your
//                 responsibility to periodically review these Terms of Use to stay informed of updates.
//             </p>
//             <p>
//                 For the purpose of these Terms of Use, wherever the context so requires ‘you’, 'your' or ‘user’ shall
//                 mean any natural or legal person who has agreed to become a user/buyer on the Platform.
//             </p>
//             {/* <h2>Agreement</h2> */}
//             <p>
//                 ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
//                 AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
//                 SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
//             </p>
//             <p>
//                 The use of Platform and/or availing of our Services is subject to the following Terms of Use:
//             </p>
//             </ol>
//             <ul>
//                 <li>
//                     To access and use the Services, you agree to provide true, accurate and complete information
//                     to us during and after registration, and you shall be responsible for all acts done through the
//                     use of your registered account on the Platform.
//                 </li>
//                 <li>
//                     Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
//                     timeliness, performance, completeness or suitability of the information and materials offered
//                     on this website or through the Services, for any specific purpose. You acknowledge that such
//                     information and materials may contain inaccuracies or errors and we expressly exclude
//                     liability for any such inaccuracies or errors to the fullest extent permitted by law.
//                 </li>
//                 <li>
//                     Your use of our Services and the Platform is solely and entirely at your own risk and
//                     discretion for which we shall not be liable to you in any manner. You are required to
//                     independently assess and ensure that the Services meet your requirements.
//                 </li>
//                 <li>
//                     The contents of the Platform and the Services are proprietary to us and are licensed to us.
//                     You will not have any authority to claim any intellectual property rights, title, or interest in
//                     its contents. The contents include and are not limited to the design, layout, look and graphics.
//                 </li>
//                 <li>
//                     You acknowledge that unauthorized use of the Platform and/or the Services may lead to
//                     action against you as per these Terms of Use and/or applicable laws.
//                 </li>
//                 <li>
//                     You agree to pay us the charges associated with availing the Services.
//                 </li>
//                 <li>
//                     You agree not to use the Platform and/or Services for any purpose that is unlawful, illegal or
//                     forbidden by these Terms, or Indian or local laws that might apply to you.
//                 </li>
//                 <li>
//                     You agree and acknowledge that the website and the Services may contain links to other third
//                     party websites. On accessing these links, you will be governed by the terms of use, privacy
//                     policy and such other policies of such third party websites. These links are provided for your
//                     convenience to provide further information.
//                 </li>
//             </ul>
//         </div>


//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 </Row>
//             </Container>

//             <Footer />
//         </>
//     );
// }



import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../firebase.config';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import UserHeader from './UserHeader';
import Footer from './Footer';
import '../CSS/termandcondition.css';

export default function TermCondition() {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, navigate to the dashboard
                navigate('/termcondition'); // Adjust the route as needed
            } else {
                // No user is signed in, navigate to the login page
                navigate('/termcondition');
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [navigate]);


 // Inline styles for lists
 const mainListStyle = {
    listStyleType: 'decimal',
    paddingLeft: '20px',
};

const subListStyle = {
    listStyleType: 'decimal',
    paddingLeft: '20px',
    counterReset: 'sub-counter',
};

const subListItemStyle = {
    counterIncrement: 'sub-counter',
  
};


    return (
        <>
            <UserHeader />

            <Container className="mt-4 mb-5">
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card className="p-4" style={{ border: 'none' }}>
                            <Card.Body>
                                <h2 className="text-center mb-5">Terms and Conditions</h2>

                                <div className="terms-container">
                                    {/* <h1>Terms & Conditions</h1> */}
                                    <ol style={mainListStyle}> {/* Specify ordered list type here */}
                                        <li>
                                            This document is an electronic record in terms of Information Technology Act, 2000 and rules
                                            there under as applicable and the amended provisions pertaining to electronic records in various
                                            statutes as amended by the Information Technology Act, 2000. This electronic record is generated
                                            by a computer system and does not require any physical or digital signatures.
                                        </li>
                                        <li>
                                            This document is published in accordance with the provisions of Rule 3 (1) of the Information
                                            Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
                                            regulations, privacy policy and Terms of Use for access or usage of domain name <b> discountfeast.co
                                            in </b> ('Website'), including the related mobile site and mobile application (hereinafter referred to as
                                            'Platform').
                                        </li>
                                        <li>
                                            The Platform is owned by <b> CAMIOS HOSPITALITY P</b>, a company incorporated under the
                                            Companies Act, 1956 with its registered office at <b> Flat 607 Bldg E Gloria Grace Sno
                                            474A474B47111216, PUNE, Pune Maharashtra, India, 411021, Pune, India</b> (hereinafter
                                            referred to as ‘Platform Owner’, 'we', 'us', 'our').
                                        </li>
                                        <li>
                                            Your use of the Platform and services and tools are governed by the following terms and
                                            conditions (“Terms of Use”) as applicable to the Platform including the applicable policies which
                                            are incorporated herein by way of reference. If You transact on the Platform, You shall be subject
                                            to the policies that are applicable to the Platform for such transaction. By mere use of the Platform,
                                            You shall be contracting with the Platform Owner and these terms and conditions including the
                                            policies constitute Your binding obligations, with Platform Owner. These Terms of Use relate to
                                            your use of our website, goods (as applicable) or services (as applicable) (collectively, 'Services').


                                            Any terms and conditions proposed by You which are in addition to or which conflict with these
                                            Terms of Use are expressly rejected by the Platform Owner and shall be of no force or effect.
                                            These Terms of Use can be modified at any time without assigning any reason. It is your
                                            responsibility to periodically review these Terms of Use to stay informed of updates.
                                        </li>
                                        <li>
                                            For the purpose of these Terms of Use, wherever the context so requires ‘you’, 'your' or ‘user’ shall
                                            mean any natural or legal person who has agreed to become a user/buyer on the Platform.
                                        </li>
                                        <li>
                                            ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
                                            AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE,
                                            SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
                                        </li>
                                     
                                        <li >
                                            The use of Platform and/or availing of our Services is subject to the following Terms of Use:
                                        </li>
                                       
                                   
                                       
                                    <ol style={subListStyle} >
                                        <li style={subListItemStyle}>
                                            To access and use the Services, you agree to provide true, accurate and complete information
                                            to us during and after registration, and you shall be responsible for all acts done through the
                                            use of your registered account on the Platform.
                                        </li>
                                        <li style={subListItemStyle}>
                                            Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
                                            timeliness, performance, completeness or suitability of the information and materials offered
                                            on this website or through the Services, for any specific purpose. You acknowledge that such
                                            information and materials may contain inaccuracies or errors and we expressly exclude
                                            liability for any such inaccuracies or errors to the fullest extent permitted by law.
                                        </li>
                                        <li style={subListItemStyle}>
                                            Your use of our Services and the Platform is solely and entirely at your own risk and
                                            discretion for which we shall not be liable to you in any manner. You are required to
                                            independently assess and ensure that the Services meet your requirements.
                                        </li>
                                        <li style={subListItemStyle}>
                                            The contents of the Platform and the Services are proprietary to us and are licensed to us.
                                            You will not have any authority to claim any intellectual property rights, title, or interest in
                                            its contents. The contents include and are not limited to the design, layout, look and graphics.
                                        </li>
                                        <li style={subListItemStyle}>
                                            You acknowledge that unauthorized use of the Platform and/or the Services may lead to
                                            action against you as per these Terms of Use and/or applicable laws.
                                        </li>
                                        <li style={subListItemStyle}>
                                            You agree to pay us the charges associated with availing the Services.
                                        </li>
                                        <li style={subListItemStyle}>
                                            You agree not to use the Platform and/or Services for any purpose that is unlawful, illegal or
                                            forbidden by these Terms, or Indian or local laws that might apply to you.
                                        </li>
                                        <li style={subListItemStyle}>
                                            You agree and acknowledge that the website and the Services may contain links to other third
                                            party websites. On accessing these links, you will be governed by the terms of use, privacy
                                            policy and such other policies of such third party websites. These links are provided for your
                                            convenience to provide further information.
                                        </li>
                                        <li style={subListItemStyle}>
                                            You understand that upon initiating a transaction for availing the Services you are entering
                                            into a legally binding and enforceable contract with the Platform Owner for the Services..
                                        </li>
                                        <li style={subListItemStyle}>
                                            You shall indemnify and hold harmless Platform Owner, its affiliates, group companies (as
                                            applicable) and their respective officers, directors, agents, and employees, from any claim or
                                            demand, or actions including reasonable attorney's fees, made by any third party or penalty
                                            imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other
                                            Policies, or Your violation of any law, rules or regulations or the rights (including
                                            infringement of intellectual property rights) of a third party.
                                        </li>
                                        <li style={subListItemStyle}>
                                            Notwithstanding anything contained in these Terms of Use, the parties shall not be liable for
                                            any failure to perform an obligation under these Terms if performance is prevented or
                                            delayed by a force majeure event..
                                        </li>
                                        <li style={subListItemStyle}>
                                            These Terms and any dispute or claim relating to it, or its enforceability, shall be governed
                                            by and construed in accordance with the laws of India..
                                        </li>
                                        <li style={subListItemStyle}>
                                            All disputes arising out of or in connection with these Terms shall be subject to the exclusive
                                            jurisdiction of the courts in <b>Pune</b> and <b> Maharashtra.</b>

                                        </li>
                                        <li style={subListItemStyle}>
                                            All concerns or communications relating to these Terms must be communicated to us using
                                            the contact information provided on this website
                                        </li>

                                    </ol>
                                    </ol>
                                   
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    );
}
