
// import React, { useEffect, useState } from 'react';
// import '../CSS/Offercards.css';
// import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
// import axios from 'axios';
// import { auth } from '../firebase.config';
// import { QRCodeCanvas } from 'qrcode.react';
// import { useNavigate } from 'react-router-dom';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import UserHeader from './UserHeader';
// import Footer from './Footer';
// import middleimg from '../assets/DFslide1.jpeg';
// import slideimg2 from '../assets/slide2.jpeg';
// import slideimg3 from '../assets/slide3.jpeg';
// import slideimg4 from '../assets/slide4.jpeg';
// import { ToastContainer } from "react-toastify";
// import { toastError, toastSuccess } from '../util';
// import 'react-toastify/dist/ReactToastify.css';


// export default function Offercards() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [coupons, setCoupons] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [couponsPerPage] = useState(10); // Display 10 coupons per page
//   const [totalPages, setTotalPages] = useState(0);
//   const [selectedHotelCoupons, setSelectedHotelCoupons] = useState([]);
//   const [selectedCoupon, setSelectedCoupon] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [showDetailsModal, setShowDetailsModal] = useState(false);
//   const [noOffers, setNoOffers] = useState(false);
//   const [isRedeeming, setIsRedeeming] = useState(false);
//   const [message, setMessage] = useState('');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [noOfGuest, setNoOfGuest] = useState('');
//   const [hostName, setHostName] = useState('');
//   const [showLoginPrompt, setShowLoginPrompt] = useState(false);
//   const [showRegisterModal, setShowRegisterModal] = useState(false);
//   const [userName, setUserName] = useState('');
//   const navigate = useNavigate();

//   const isMobile = window.innerWidth < 768;

//   const isSessionActive = () => {
//     return localStorage.getItem("session") !== null;
//   };

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setUserName(user.displayName || 'User');
//       } else {
//         setUserName('');
//         if (!isSessionActive()) {
//           navigate('/');
//         }
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate]);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setIsLoggedIn(true);
//       } else {
//         setIsLoggedIn(false);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   const handleClaim = () => {
//     if (isLoggedIn) {
//       setShowDetailsModal(true);
//     } else {
//       setShowRegisterModal(true);
//     }
//   };

//   const handleCloseRegisterModal = () => {
//     setShowRegisterModal(false);
//   };

//   const fetchCoupons = async (page) => {
//     try {
//       const response = await axios.get(`https://discountfeast.co.in/showcoupon/getRandomCoupons?page=${page}&limit=${couponsPerPage}`);
//       setCoupons(response.data.coupons);
//       setTotalPages(response.data.totalPages); // Assuming the backend sends total pages
//     } catch (error) {
//       console.error('Failed to fetch coupons', error);
//     }
//   };

//   useEffect(() => {
//     fetchCoupons(currentPage);
//   }, [currentPage]);

//   const handleOfferClick = async (hotelId) => {
//     try {
//       const response = await axios.get(`https://discountfeast.co.in/couponredeem/hotel/${hotelId}`);
      
//       if (response.data.length === 0) {
//         setNoOffers(true);
//       } else {
//         setSelectedHotelCoupons(response.data);
//         setNoOffers(false);
//       }
//       setShowModal(true);
//     } catch (error) {
//       console.error('Failed to fetch coupons for the hotel', error);
//       alert('Offers are not available');
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedHotelCoupons([]);
//     setSelectedCoupon(null);
//     setNoOffers(false);
//   };

//   const handleCloseDetailsModal = () => {
//     setShowDetailsModal(false);
//     setSelectedCoupon(null);
//   };

//   const handleMobileNumberChange = (e) => {
//     setMobileNumber(e.target.value);
//   };

//   const handleCouponClick = (coupon) => {
//     setSelectedCoupon(coupon);
//     handleClaim();
//   };

//   const handleRedeem = async () => {
//     const regMobNo = localStorage.getItem('userPhoneNumber');
//     setIsRedeeming(true);

//     try {
//       const currentUser = auth.currentUser;
//       if (!currentUser) {
//         setShowLoginPrompt(true);
//         setIsRedeeming(false);
//         return;
//       }

//       if (mobileNumber.length !== 10) {
//         alert('Please enter a valid 10-digit mobile number.');
//         return;
//       }

//       const res = await axios.post('https://discountfeast.co.in/couponredeem/setRedeemedDetials', {
//         phoneNumber: regMobNo,
//         userMobNo: mobileNumber,
//         hostName: hostName,
//         noOfGuest: noOfGuest,
//         couponDetail: selectedCoupon,
//       });

//       const resData = res?.data;

//       if (resData?.success) {
//         toastSuccess("Coupon redeemed successfully");

//         await axios.post('https://discountfeast.co.in/emailredeem/sendEmail', {
//           phoneNumber: regMobNo,
//         });

//         setMessage("Coupon redeemed successfully");
//       } else {
//         setMessage("Failed to redeem coupon");
//         toastError("Failed to redeem coupon");
//       }

//       setShowDetailsModal(false);
//       setShowModal(false);
//     } catch (error) {
//       console.error('Error during redeeming coupon or sending email:', error);
//       setMessage('Failed to redeem coupon.');
//     } finally {
//       setIsRedeeming(false);
//     }
//   };

//   const settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     arrows: false,
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <Button
//           key={i}
//           style={{background: 'transparent', border: 'none', color:'black', fontSize: '18px' }}
//           onClick={() => handlePageChange(i)}
//           className={` ${i === currentPage ? 'active' : 'mx-1 page-btn'}`}
//         >
//           {i}
//         </Button>
//       );
//     }
//     return pageNumbers;
//   };

//   return (
//     <>
//       <UserHeader />
//       <Container>
//         <Row>
//           <Col lg={12} className='mt-4' style={{ borderRadius: '8px' }}>
//             <Slider {...settings}>
//               <div>
//                 <img src={middleimg} alt="Slide 1" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg2} alt="Slide 2" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg3} alt="Slide 3" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg4} alt="Slide 4" className="slider-img" />
//               </div>
//             </Slider>
//           </Col>
//         </Row>

//         <Row className='maincardd'>
//           {coupons.map((coupon) => (
//             <Col lg={3} md={4} sm={6} key={coupon._id} className="mb-4">
//               <div className="custom-coupon">
//                 <img
//                   src={`https://discountfeast.co.in/${coupon.couponImage}`}
//                   alt={coupon.hotelName}
//                   className="custom-coupon-image"
//                   style={{ width: '100%', height: '150px', borderRadius: '8px' }}
//                 />
//                 <div className="custom-coupon-details">
//                   <h5>{coupon.hotelName}</h5>
//                   <Button
//                     className='py-0 px-2'
//                     style={{ width: '50%', fontSize: '14px', background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }}
//                     onClick={() => handleOfferClick(coupon.hotelId)}
//                   >
//                     Show Offers
//                   </Button>
//                   <p className="pt-2">Hotel: {coupon.hotelId}</p>
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>

//         {/* Pagination Controls */}
//         <div className="pagination-controls d-flex justify-content-center align-items-center mb-2">
//           <Button style={{color:'black', background:'none', fontWeight:'bold', border:'none'}} onClick={handlePrevPage} disabled={currentPage === 1} className="mx-1 page-btn">
//             Prev
//           </Button>
//           {renderPageNumbers()}
//           <Button style={{color:'black',background:'none',fontWeight:'bold', border:'none'}} onClick={handleNextPage} disabled={currentPage === totalPages} className="mx-1 page-btn">
//             Next
//           </Button>
//         </div>
//       </Container>
//       <Modal show={showModal} onHide={handleCloseModal} style={{ color: 'black' }} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {noOffers ? (
//             <p>No offers available for this hotel.</p>
//           ) : (
//             <div className="row container justify-content-center">
//               {selectedHotelCoupons.map((coupon, index) => (
//                 <div key={index} className="col-md-12 mb-3" style={{ marginLeft: '10px' }}>
//                   <div className="cardpop d-flex flex-row h-100 justify-content-center align-items-center">
//                     <div className="col-lg-4 p-1 d-flex justify-content-center">
//                       <img
//                         src={`https://discountfeast.co.in/${coupon.couponImage}`}
//                         alt={coupon.title}
//                         className="img-fluid"
//                         style={{ width: '100px', height: '100px' }}
//                       />
//                     </div>
//                     <div className="card-body col-lg-8 d-flex flex-column justify-content-center align-items-center">
//                       <h1 className="card-text">
//                         {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'} OFF
//                       </h1>
//                       <h6 className="card-title">
//                         {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'}
//                         &nbsp; off&nbsp;{coupon.title}
//                       </h6>
//                       <Button className='p-0 px-4 mt-2' style={{ backgroundColor: '#EF4C0B', border: 'none' }} onClick={() => handleCouponClick(coupon)}>
//                         Claim
//                       </Button>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showDetailsModal} onHide={handleCloseDetailsModal} style={{ color: 'black' }} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className='w-100 text-center'>Redeem Coupon</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedCoupon && (
//             <>
//               <Container className=' cardpop d-flex flex-column justify-content-center px-0'>

//                 <div className="d-flex   flex-row h-100">
//                   <div className="col-lg-4 p-3">
//                     <div className=' d-flex justify-content-center'>
//                       <img
//                         src={`https://discountfeast.co.in/${selectedCoupon.couponImage}`}
//                         alt={selectedCoupon.title}
//                         className="img-fluid"
//                         style={{ width: '100px', height: '100px' }}
//                       />
//                     </div>
//                   </div>

//                   <div className="col-lg-8 d-flex flex-column  " style={{ paddingTop: '10px' }}>

//                     <Modal.Title  >{selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'} OFF</Modal.Title>

//                     <p>{selectedCoupon.brand}</p>
//                     <h6> {selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'}
//                       &nbsp; off&nbsp;{selectedCoupon.title}</h6>


//                   </div >
//                 </div>
//                 <div className='pl-5'>
//                   <h6 className='cpcode'>CouponCode:<span > {selectedCoupon.couponCode}</span></h6>

//                   {/* Display description as a list */}

//                   <ul className="coupon-description-list" style={{ listStyle: 'none' }}>
//                     {selectedCoupon.description.split('\n').map((point, index) => (
//                       <li key={index}>{point}</li>
//                     ))}
//                   </ul>
//                 </div>


//                 <Row className='justify-content-center'>
//                   <div style={{ borderBottom: '2px dashed gray', width: '85%' }}>

//                   </div>
//                   {/* <Col className="d-flex justify-content-center">
//                   <img src={Horizontal} alt="Horizontal Line" className="horizontal-line" />
//                 </Col> */}
//                 </Row>
//                 {/* <Row className="d-flex justify-content-center">
//               <Col md={6} className="d-flex justify-content-center">
//                 <img src={QRsacn} alt="QR Code" className="qr-code-img" />
//               </Col>
//             </Row> */}
//                 <Col>
//                   <Row className="d-flex justify-content-center mt-3">
//                     {/* Generate QR Code */}
//                     <QRCodeCanvas
//                       value={`Hotel: ${selectedCoupon.hotelName}, Location: ${selectedCoupon.location}, City: ${selectedCoupon.city}, Coupon Code: ${selectedCoupon.couponCode}`}
//                       size={120}
//                       bgColor={"#ffffff"}
//                       fgColor={"#000000"}
//                       style={{ height: '100px' }}
//                       level={"L"}
//                     />
//                   </Row>
//                 </Col>
//                 {/* <Row>
//               <Col className="d-flex justify-content-center">
//                 <p className="text-muted">Scan to Redeem</p>
//               </Col>
//             </Row> */}

//                 <Row className=" justify-content-center">
//                   <Col md={8} className=" justify-content-center">
//                     <Form onSubmit={(e) => {
//                       e.preventDefault();
//                       handleRedeem();
//                     }}>
//                       <Form.Group controlId="guestnumber">
//                         {/* <Form.Label>Number of Guests:</Form.Label> */}
//                         <Form.Control
//                           style={{ marginTop: '10px' }}
//                           type="number"
//                           placeholder="Enter number of guests"
//                           value={noOfGuest}
//                           onChange={(e) => setNoOfGuest(e.target.value)}
//                           required
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="mobileNumber">
//                         {/* <Form.Label>Contact Number:</Form.Label> */}
//                         <Form.Control
//                           style={{ marginTop: '10px' }}
//                           type="text"
//                           placeholder="Enter mobile number"
//                           value={mobileNumber}
//                           onChange={(e) => {
//                             // Restrict to 10 digits
//                             if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
//                               setMobileNumber(e.target.value);
//                             }
//                           }}
//                           maxLength="10" // Ensure input length is max 10 digits
//                           pattern="[0-9]{10}" // Regex for exactly 10 digits
//                           required
//                         />
//                       </Form.Group>

//                       <Button className='p-1 px-2 mt-2 justify-content-center' style={{ background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }} variant="primary" type="submit" disabled={isRedeeming}>
//                         {isRedeeming ? 'Redeeming...' : 'Redeem Now'}

//                       </Button>
//                     </Form>
//                   </Col>
//                 </Row>

//                 {message && <Row className="mt-3">
//                   <Col className="d-flex justify-content-center">
//                     <p className="text-danger">{message}</p>
//                   </Col>
//                 </Row>}
//               </Container>

//             </>
//           )}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className='w-100 text-center'>Login or Register</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p className='pb-4'>To claim this offer, please log in or purchase a membership.</p>
//           <div className='d-flex '>
//             <Button
//               variant="primary"
//               size="sm" // Makes the button small
//               onClick={() => navigate('/signin')} // Navigate to /signin
//               style={{ padding: '5 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'atuo', marginRight: '20px' }} // Smaller width
//             >
//               One Time Registration
//             </Button>
//             <Button
//               variant="primary"
//               size="sm" // Makes the button small
//               onClick={() => navigate('/signupform')} // Navigate to /signin
//               style={{ padding: '5px 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'auto', marginLeftLeft: '10px' }} // Smaller width
//             >
//               Member Login
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//       <Footer />
//       <ToastContainer/>
//     </>
//   );
// }





// import React, { useEffect, useState } from 'react';
// import '../CSS/Offercards.css';
// import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
// import axios from 'axios';
// import { auth } from '../firebase.config';
// import { QRCodeCanvas } from 'qrcode.react';
// import { useNavigate } from 'react-router-dom';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import UserHeader from './UserHeader';
// import Footer from './Footer';
// import middleimg from '../assets/DFslide1.jpeg';
// import slideimg2 from '../assets/slide2.jpeg';
// import slideimg3 from '../assets/slide3.jpeg';
// import slideimg4 from '../assets/slide4.jpeg';
// import { ToastContainer } from "react-toastify";
// import { toastError, toastSuccess } from '../util';
// import 'react-toastify/dist/ReactToastify.css';


// export default function Offercards() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [coupons, setCoupons] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [couponsPerPage] = useState(10); // Display 10 coupons per page
//   const [totalPages, setTotalPages] = useState(0);
//   const [selectedHotelCoupons, setSelectedHotelCoupons] = useState([]);
//   const [selectedCoupon, setSelectedCoupon] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [showDetailsModal, setShowDetailsModal] = useState(false);
//   const [noOffers, setNoOffers] = useState(false);
//   const [isRedeeming, setIsRedeeming] = useState(false);
//   const [message, setMessage] = useState('');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [noOfGuest, setNoOfGuest] = useState('');
//   const [hostName, setHostName] = useState('');
//   const [showLoginPrompt, setShowLoginPrompt] = useState(false);
//   const [showRegisterModal, setShowRegisterModal] = useState(false);
//   const [userName, setUserName] = useState('');
//    const [searchTerm, setSearchTerm] = useState(''); // New search term state
//   const [filteredCoupons, setFilteredCoupons] = useState([]); 
//   const navigate = useNavigate();

//   const isMobile = window.innerWidth < 768;

//   const isSessionActive = () => {
//     return localStorage.getItem("session") !== null;
//   };

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setUserName(user.displayName || 'User');
//       } else {
//         setUserName('');
//         if (!isSessionActive()) {
//           navigate('/');
//         }
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate]);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setIsLoggedIn(true);
//       } else {
//         setIsLoggedIn(false);
//       }
//     });
//     return () => unsubscribe();
//   }, []);

//   const handleClaim = () => {
//     if (isLoggedIn) {
//       setShowDetailsModal(true);
//     } else {
//       setShowRegisterModal(true);
//     }
//   };

//   const handleCloseRegisterModal = () => {
//     setShowRegisterModal(false);
//   };

//   const fetchCoupons = async (page) => {
//     try {
//       const response = await axios.get(`https://discountfeast.co.in/showcoupon/getRandomCoupons?page=${page}&limit=${couponsPerPage}`);
//       setCoupons(response.data.coupons);
//       setFilteredCoupons(response.data.coupons); // Initialize with fetched coupons
//       setTotalPages(response.data.totalPages); // Assuming the backend sends total pages
//     } catch (error) {
//       console.error('Failed to fetch coupons', error);
//     }
//   };

//   useEffect(() => {
//     fetchCoupons(currentPage);
//   }, [currentPage]);


// // Function to filter coupons based on the search term
// const handleSearch = (e) => {
//   const searchTerm = e.target.value.toLowerCase();
//   setSearchTerm(searchTerm);

//   if (searchTerm === '') {
//     setFilteredCoupons(coupons); // Show all coupons if search is cleared
//   } else {
//     const filtered = coupons.filter((coupon) =>
//       coupon.hotelName.toLowerCase().includes(searchTerm) ||
//       coupon.city.toLowerCase().includes(searchTerm) ||
//       coupon.location.toLowerCase().includes(searchTerm)
//     );
//     setFilteredCoupons(filtered);
//   }
// };


//   const handleOfferClick = async (hotelId) => {
//     try {
//       const response = await axios.get(`https://discountfeast.co.in/couponredeem/hotel/${hotelId}`);
      
//       if (response.data.length === 0) {
//         setNoOffers(true);
//       } else {
//         setSelectedHotelCoupons(response.data);
//         setNoOffers(false);
//       }
//       setShowModal(true);
//     } catch (error) {
//       console.error('Failed to fetch coupons for the hotel', error);
//       alert('Offers are not available');
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedHotelCoupons([]);
//     setSelectedCoupon(null);
//     setNoOffers(false);
//   };

//   const handleCloseDetailsModal = () => {
//     setShowDetailsModal(false);
//     setSelectedCoupon(null);
//   };

//   const handleMobileNumberChange = (e) => {
//     setMobileNumber(e.target.value);
//   };

//   const handleCouponClick = (coupon) => {
//     setSelectedCoupon(coupon);
//     handleClaim();
//   };

//   const handleRedeem = async () => {
//     const regMobNo = localStorage.getItem('userPhoneNumber');
//     setIsRedeeming(true);

//     try {
//       const currentUser = auth.currentUser;
//       if (!currentUser) {
//         setShowLoginPrompt(true);
//         setIsRedeeming(false);
//         return;
//       }

//       if (mobileNumber.length !== 10) {
//         alert('Please enter a valid 10-digit mobile number.');
//         return;
//       }

//       const res = await axios.post('https://discountfeast.co.in/couponredeem/setRedeemedDetials', {
//         phoneNumber: regMobNo,
//         userMobNo: mobileNumber,
//         hostName: hostName,
//         noOfGuest: noOfGuest,
//         couponDetail: selectedCoupon,
//       });

//       const resData = res?.data;

//       if (resData?.success) {
//         toastSuccess("Coupon redeemed successfully");

//         await axios.post('https://discountfeast.co.in/emailredeem/sendEmail', {
//           phoneNumber: regMobNo,
//         });

//         setMessage("Coupon redeemed successfully");
//       } else {
//         setMessage("Failed to redeem coupon");
//         toastError("Failed to redeem coupon");
//       }

//       setShowDetailsModal(false);
//       setShowModal(false);
//     } catch (error) {
//       console.error('Error during redeeming coupon or sending email:', error);
//       setMessage('Failed to redeem coupon.');
//     } finally {
//       setIsRedeeming(false);
//     }
//   };

//   const settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     arrows: false,
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <Button
//           key={i}
//           style={{background: 'transparent', border: 'none', color:'black', fontSize: '18px' }}
//           onClick={() => handlePageChange(i)}
//           className={` ${i === currentPage ? 'active' : 'mx-1 page-btn'}`}
//         >
//           {i}
//         </Button>
//       );
//     }
//     return pageNumbers;
//   };

//   return (
//     <>
//       <UserHeader />
//       <Container>
//         <Row>
//           <Col lg={12} className='mt-4' style={{ borderRadius: '8px' }}>
//             <Slider {...settings}>
//               <div>
//                 <img src={middleimg} alt="Slide 1" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg2} alt="Slide 2" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg3} alt="Slide 3" className="slider-img" />
//               </div>
//               <div>
//                 <img src={slideimg4} alt="Slide 4" className="slider-img" />
//               </div>
//             </Slider>
//           </Col>
//         </Row>
//         <Row className="my-3">
//           <Col lg={4}>
//             <Form.Control
//               type="text"
//               placeholder="Search by merchant name, city, or location"
//               value={searchTerm}
//               onChange={handleSearch}
//               className="mb-3"
//             />
//           </Col>
//         </Row>
//         <Row className='maincardd'>
//           {filteredCoupons.map((coupon) => (
//             <Col lg={3} md={4} sm={6} key={coupon._id} className="mb-4">
//               <div className="custom-coupon">
//                 <img
//                   src={`https://discountfeast.co.in/${coupon.couponImage}`}
//                   alt={coupon.hotelName}
//                   className="custom-coupon-image"
//                   style={{ width: '100%', height: '150px', borderRadius: '8px' }}
//                 />
//                 <div className="custom-coupon-details">
//                   <h5>{coupon.hotelName}</h5>
//                   <Button
//                     className='py-0 px-2'
//                     style={{ width: '50%', fontSize: '14px', background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }}
//                     onClick={() => handleOfferClick(coupon.hotelId)}
//                   >
//                     Show Offers
//                   </Button>
//                   <p className="pt-2">Hotel: {coupon.hotelId}</p>
//                 </div>
//               </div>
//             </Col>
//           ))}
//         </Row>

//         {/* Pagination Controls */}
//         <div className="pagination-controls d-flex justify-content-center align-items-center mb-2">
//           <Button style={{color:'black', background:'none', fontWeight:'bold', border:'none'}} onClick={handlePrevPage} disabled={currentPage === 1} className="mx-1 page-btn">
//             Prev
//           </Button>
//           {renderPageNumbers()}
//           <Button style={{color:'black',background:'none',fontWeight:'bold', border:'none'}} onClick={handleNextPage} disabled={currentPage === totalPages} className="mx-1 page-btn">
//             Next
//           </Button>
//         </div>
//       </Container>
//       <Modal show={showModal} onHide={handleCloseModal} style={{ color: 'black' }} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {noOffers ? (
//             <p>No offers available for this hotel.</p>
//           ) : (
//             <div className="row container justify-content-center">
//               {selectedHotelCoupons.map((coupon, index) => (
//                 <div key={index} className="col-md-12 mb-3" style={{ marginLeft: '10px' }}>
//                   <div className="cardpop d-flex flex-row h-100 justify-content-center align-items-center">
//                     <div className="col-lg-4 p-1 d-flex justify-content-center">
//                       <img
//                         src={`https://discountfeast.co.in/${coupon.couponImage}`}
//                         alt={coupon.title}
//                         className="img-fluid"
//                         style={{ width: '100px', height: '100px' }}
//                       />
//                     </div>
//                     <div className="card-body col-lg-8 d-flex flex-column justify-content-center align-items-center">
//                       <h1 className="card-text">
//                         {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'} OFF
//                       </h1>
//                       <h6 className="card-title">
//                         {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'}
//                         &nbsp; off&nbsp;{coupon.title}
//                       </h6>
//                       <Button className='p-0 px-4 mt-2' style={{ backgroundColor: '#EF4C0B', border: 'none' }} onClick={() => handleCouponClick(coupon)}>
//                         Claim
//                       </Button>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showDetailsModal} onHide={handleCloseDetailsModal} style={{ color: 'black' }} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className='w-100 text-center'>Redeem Coupon</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedCoupon && (
//             <>
//               <Container className=' cardpop d-flex flex-column justify-content-center px-0'>

//                 <div className="d-flex   flex-row h-100">
//                   <div className="col-lg-4 p-3">
//                     <div className=' d-flex justify-content-center'>
//                       <img
//                         src={`https://discountfeast.co.in/${selectedCoupon.couponImage}`}
//                         alt={selectedCoupon.title}
//                         className="img-fluid"
//                         style={{ width: '100px', height: '100px' }}
//                       />
//                     </div>
//                   </div>

//                   <div className="col-lg-8 d-flex flex-column  " style={{ paddingTop: '10px' }}>

//                     <Modal.Title  >{selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'} OFF</Modal.Title>

//                     <p>{selectedCoupon.brand}</p>
//                     <h6> {selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'}
//                       &nbsp; off&nbsp;{selectedCoupon.title}</h6>


//                   </div >
//                 </div>
//                 <div className='pl-5'>
//                   <h6 className='cpcode'>CouponCode:<span > {selectedCoupon.couponCode}</span></h6>

//                   {/* Display description as a list */}

//                   <ul className="coupon-description-list" style={{ listStyle: 'none' }}>
//                     {selectedCoupon.description.split('\n').map((point, index) => (
//                       <li key={index}>{point}</li>
//                     ))}
//                   </ul>
//                 </div>


//                 <Row className='justify-content-center'>
//                   <div style={{ borderBottom: '2px dashed gray', width: '85%' }}>

//                   </div>
//                   {/* <Col className="d-flex justify-content-center">
//                   <img src={Horizontal} alt="Horizontal Line" className="horizontal-line" />
//                 </Col> */}
//                 </Row>
//                 {/* <Row className="d-flex justify-content-center">
//               <Col md={6} className="d-flex justify-content-center">
//                 <img src={QRsacn} alt="QR Code" className="qr-code-img" />
//               </Col>
//             </Row> */}
//                 <Col>
//                   <Row className="d-flex justify-content-center mt-3">
//                     {/* Generate QR Code */}
//                     <QRCodeCanvas
//                       value={`Hotel: ${selectedCoupon.hotelName}, Location: ${selectedCoupon.location}, City: ${selectedCoupon.city}, Coupon Code: ${selectedCoupon.couponCode}`}
//                       size={120}
//                       bgColor={"#ffffff"}
//                       fgColor={"#000000"}
//                       style={{ height: '100px' }}
//                       level={"L"}
//                     />
//                   </Row>
//                 </Col>
//                 {/* <Row>
//               <Col className="d-flex justify-content-center">
//                 <p className="text-muted">Scan to Redeem</p>
//               </Col>
//             </Row> */}

//                 <Row className=" justify-content-center">
//                   <Col md={8} className=" justify-content-center">
//                     <Form onSubmit={(e) => {
//                       e.preventDefault();
//                       handleRedeem();
//                     }}>
//                       <Form.Group controlId="guestnumber">
//                         {/* <Form.Label>Number of Guests:</Form.Label> */}
//                         <Form.Control
//                           style={{ marginTop: '10px' }}
//                           type="number"
//                           placeholder="Enter number of guests"
//                           value={noOfGuest}
//                           onChange={(e) => setNoOfGuest(e.target.value)}
//                           required
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="mobileNumber">
//                         {/* <Form.Label>Contact Number:</Form.Label> */}
//                         <Form.Control
//                           style={{ marginTop: '10px' }}
//                           type="text"
//                           placeholder="Enter mobile number"
//                           value={mobileNumber}
//                           onChange={(e) => {
//                             // Restrict to 10 digits
//                             if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
//                               setMobileNumber(e.target.value);
//                             }
//                           }}
//                           maxLength="10" // Ensure input length is max 10 digits
//                           pattern="[0-9]{10}" // Regex for exactly 10 digits
//                           required
//                         />
//                       </Form.Group>

//                       <Button className='p-1 px-2 mt-2 justify-content-center' style={{ background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }} variant="primary" type="submit" disabled={isRedeeming}>
//                         {isRedeeming ? 'Redeeming...' : 'Redeem Now'}

//                       </Button>
//                     </Form>
//                   </Col>
//                 </Row>

//                 {message && <Row className="mt-3">
//                   <Col className="d-flex justify-content-center">
//                     <p className="text-danger">{message}</p>
//                   </Col>
//                 </Row>}
//               </Container>

//             </>
//           )}
//         </Modal.Body>
//       </Modal>

//       <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} centered>
//         <Modal.Header closeButton>
//           <Modal.Title className='w-100 text-center'>Login or Register</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p className='pb-4'>To claim this offer, please log in or purchase a membership.</p>
//           <div className='d-flex '>
//             <Button
//               variant="primary"
//               size="sm" // Makes the button small
//               onClick={() => navigate('/signin')} // Navigate to /signin
//               style={{ padding: '5 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'atuo', marginRight: '20px' }} // Smaller width
//             >
//               One Time Registration
//             </Button>
//             <Button
//               variant="primary"
//               size="sm" // Makes the button small
//               onClick={() => navigate('/signupform')} // Navigate to /signin
//               style={{ padding: '5px 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'auto', marginLeftLeft: '10px' }} // Smaller width
//             >
//               Member Login
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//       <Footer />
//       <ToastContainer/>
//     </>
//   );
// }




import React, { useEffect, useState } from 'react';
import '../CSS/Offercards.css';
import { Button, Col, Container, Row, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { auth } from '../firebase.config';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserHeader from './UserHeader';
import Footer from './Footer';
import middleimg from '../assets/DFslide1.jpeg';
import slideimg2 from '../assets/slide2.jpeg';
import slideimg3 from '../assets/slide3.jpeg';
import slideimg4 from '../assets/slide4.jpeg';
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from '../util';
import 'react-toastify/dist/ReactToastify.css';


export default function Offercards() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponsPerPage] = useState(10); // Display 10 coupons per page
  const [totalPages, setTotalPages] = useState(0);
  const [selectedHotelCoupons, setSelectedHotelCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [noOffers, setNoOffers] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [message, setMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [noOfGuest, setNoOfGuest] = useState('');
  const [hostName, setHostName] = useState('');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [userName, setUserName] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // New search term state
  const [filteredCoupons, setFilteredCoupons] = useState([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);


  const navigate = useNavigate();

  const isMobile = window.innerWidth < 768;

  const isSessionActive = () => {
    return localStorage.getItem("session") !== null;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserName(user.displayName || 'User');
      } else {
        setUserName('');
        if (!isSessionActive()) {
          navigate('/');
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleClaim = () => {
    if (isLoggedIn) {
      setShowDetailsModal(true);
    } else {
      setShowRegisterModal(true);
    }
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const fetchCoupons = async (page) => {
    try {
      const response = await axios.get(`https://discountfeast.co.in/showcoupon/getRandomCoupons?page=${page}&limit=${couponsPerPage}`);
      setCoupons(response.data.coupons);
      setFilteredCoupons(response.data.coupons); // Initialize with fetched coupons
      setTotalPages(response.data.totalPages); // Assuming the backend sends total pages
    } catch (error) {
      console.error('Failed to fetch coupons', error);
    }
  };

  useEffect(() => {
    fetchCoupons(currentPage);
  }, [currentPage]);

  //for handle debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); 

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);


  useEffect(() => {
    if (!debouncedSearchTerm.trim()) {
      // setCoupons([]); 
      fetchCoupons();
      return;
    }

    const getCoupons = async () => {

      try {
        const response = await axios.get(`https://discountfeast.co.in/showcoupon/searchCoupons?q=${debouncedSearchTerm}`);
        setFilteredCoupons(response?.data?.coupons);
      } catch (err) {
        // setError('No coupons found or an error occurred.');
      }
    };

    getCoupons();
  }, [debouncedSearchTerm]);


  // Function to filter coupons based on the search term
  // const handleSearch = (e) => {
  //   const searchTerm = e.target.value.toLowerCase();
  //   setSearchTerm(searchTerm);

  //   if (searchTerm === '') {
  //     setFilteredCoupons(coupons); // Show all coupons if search is cleared
  //   } else {
  //     const filtered = coupons.filter((coupon) =>
  //       coupon.hotelName.toLowerCase().includes(searchTerm) ||
  //       coupon.city.toLowerCase().includes(searchTerm) ||
  //       coupon.location.toLowerCase().includes(searchTerm)
  //     );
  //     setFilteredCoupons(filtered);
  //   }
  // };


  const handleOfferClick = async (hotelId) => {
    try {
      const response = await axios.get(`https://discountfeast.co.in/couponredeem/hotel/${hotelId}`);

      if (response.data.length === 0) {
        setNoOffers(true);
      } else {
        setSelectedHotelCoupons(response.data);
        setNoOffers(false);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Failed to fetch coupons for the hotel', error);
      alert('Offers are not available');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedHotelCoupons([]);
    setSelectedCoupon(null);
    setNoOffers(false);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedCoupon(null);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleCouponClick = (coupon) => {
    setSelectedCoupon(coupon);
    handleClaim();
  };

  const handleRedeem = async () => {
    const regMobNo = localStorage.getItem('userPhoneNumber');
    setIsRedeeming(true);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setShowLoginPrompt(true);
        setIsRedeeming(false);
        return;
      }

      if (mobileNumber.length !== 10) {
        alert('Please enter a valid 10-digit mobile number.');
        return;
      }

      const res = await axios.post('https://discountfeast.co.in/couponredeem/setRedeemedDetials', {
        phoneNumber: regMobNo,
        userMobNo: mobileNumber,
        hostName: hostName,
        noOfGuest: noOfGuest,
        couponDetail: selectedCoupon,
      });

      const resData = res?.data;

      if (resData?.success) {
        toastSuccess("Coupon redeemed successfully");

        await axios.post('https://discountfeast.co.in/emailredeem/sendEmail', {
          phoneNumber: regMobNo,
        });

        setMessage("Coupon redeemed successfully");
      } else {
        setMessage("Failed to redeem coupon");
        toastError("Failed to redeem coupon");
      }

      setShowDetailsModal(false);
      setShowModal(false);
    } catch (error) {
      console.error('Error during redeeming coupon or sending email:', error);
      setMessage('Failed to redeem coupon.');
    } finally {
      setIsRedeeming(false);
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Button
          key={i}
          style={{ background: 'transparent', border: 'none', color: 'black', fontSize: '18px' }}
          onClick={() => handlePageChange(i)}
          className={` ${i === currentPage ? 'active' : 'mx-1 page-btn'}`}
        >
          {i}
        </Button>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      <UserHeader />
      <Container>
        <Row>
          <Col lg={12} className='mt-4' style={{ borderRadius: '8px' }}>
            <Slider {...settings}>
              <div>
                <img src={middleimg} alt="Slide 1" className="slider-img" />
              </div>
              <div>
                <img src={slideimg2} alt="Slide 2" className="slider-img" />
              </div>
              <div>
                <img src={slideimg3} alt="Slide 3" className="slider-img" />
              </div>
              <div>
                <img src={slideimg4} alt="Slide 4" className="slider-img" />
              </div>
            </Slider>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={4}>
            <Form.Control
              type="text"
              placeholder="Search by merchant name, city, or location"
             
              value={searchTerm}
              // onChange={handleSearch}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-3"
            />
          </Col>
        </Row>
        <Row className='maincardd'>
          {filteredCoupons.map((coupon) => (
            <Col lg={3} md={4} sm={6} key={coupon._id} className="mb-4">
              <div className="custom-coupon">
                <img
                  src={`https://discountfeast.co.in/${coupon.couponImage}`}
                  alt={coupon.hotelName}
                  className="custom-coupon-image"
                  style={{ width: '100%', height: '150px', borderRadius: '8px' }}
                />
                <div className="custom-coupon-details">
                  <h5>{coupon.hotelName}</h5>
                  <Button
                    className='py-0 px-2'
                    style={{ width: '50%', fontSize: '14px', background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }}
                    onClick={() => handleOfferClick(coupon.hotelId)}
                  >
                    Show Offers
                  </Button>
                  <p className="pt-2">Hotel: {coupon.hotelId}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {/* Pagination Controls */}
        <div className="pagination-controls d-flex justify-content-center align-items-center mb-2">
          <Button style={{ color: 'black', background: 'none', fontWeight: 'bold', border: 'none' }} onClick={handlePrevPage} disabled={currentPage === 1} className="mx-1 page-btn">
            Prev
          </Button>
          {renderPageNumbers()}
          <Button style={{ color: 'black', background: 'none', fontWeight: 'bold', border: 'none' }} onClick={handleNextPage} disabled={currentPage === totalPages} className="mx-1 page-btn">
            Next
          </Button>
        </div>
        <div className=" d-flex justify-content-center align-items-center mb-2 ">
          <h5 style={{backgroundColor:'rgb(239, 76, 11)',color:'white',padding:'5px'}}>
            This is a membership benefit program. Individual coupons are not for sale.
            </h5>
          </div>
        
      </Container>
      <Modal show={showModal} onHide={handleCloseModal} style={{ color: 'black' }} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Coupon Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noOffers ? (
            <p>No offers available for this hotel.</p>
          ) : (
            <div className="row container justify-content-center">
              {selectedHotelCoupons.map((coupon, index) => (
                <div key={index} className="col-md-12 mb-3" style={{ marginLeft: '10px' }}>
                  <div className="cardpop d-flex flex-row h-100 justify-content-center align-items-center">
                    <div className="col-lg-4 p-1 d-flex justify-content-center">
                      <img
                        src={`https://discountfeast.co.in/${coupon.couponImage}`}
                        alt={coupon.title}
                        className="img-fluid"
                        style={{ width: '100px', height: '100px' }}
                      />
                    </div>
                    <div className="card-body col-lg-8 d-flex flex-column justify-content-center align-items-center">
                      <h1 className="card-text">
                        {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'} OFF
                      </h1>
                      <h6 className="card-title">
                        {coupon.discountValue} {coupon.discountType === 'percentage' ? '%' : 'RS'}
                        &nbsp; off&nbsp;{coupon.title}
                      </h6>
                      <Button className='p-0 px-4 mt-2' style={{ backgroundColor: '#EF4C0B', border: 'none' }} onClick={() => handleCouponClick(coupon)}>
                        Claim
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showDetailsModal} onHide={handleCloseDetailsModal} style={{ color: 'black' }} centered>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>Redeem Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCoupon && (
            <>
              <Container className=' cardpop d-flex flex-column justify-content-center px-0'>

                <div className="d-flex   flex-row h-100">
                  <div className="col-lg-4 p-3">
                    <div className=' d-flex justify-content-center'>
                      <img
                        src={`https://discountfeast.co.in/${selectedCoupon.couponImage}`}
                        alt={selectedCoupon.title}
                        className="img-fluid"
                        style={{ width: '100px', height: '100px' }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-8 d-flex flex-column  " style={{ paddingTop: '10px' }}>

                    <Modal.Title  >{selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'} OFF</Modal.Title>

                    <p>{selectedCoupon.brand}</p>
                    <h6> {selectedCoupon.discountValue} {selectedCoupon.discountType === 'percentage' ? '%' : 'RS'}
                      &nbsp; off&nbsp;{selectedCoupon.title}</h6>


                  </div >
                </div>
                <div className='pl-5'>
                  <h6 className='cpcode'>CouponCode:<span > {selectedCoupon.couponCode}</span></h6>

                  {/* Display description as a list */}

                  <ul className="coupon-description-list" style={{ listStyle: 'none' }}>
                    {selectedCoupon.description.split('\n').map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </div>


                <Row className='justify-content-center'>
                  <div style={{ borderBottom: '2px dashed gray', width: '85%' }}>

                  </div>
                  {/* <Col className="d-flex justify-content-center">
                  <img src={Horizontal} alt="Horizontal Line" className="horizontal-line" />
                </Col> */}
                </Row>
                {/* <Row className="d-flex justify-content-center">
              <Col md={6} className="d-flex justify-content-center">
                <img src={QRsacn} alt="QR Code" className="qr-code-img" />
              </Col>
            </Row> */}
                <Col>
                  <Row className="d-flex justify-content-center mt-3">
                    {/* Generate QR Code */}
                    <QRCodeCanvas
                      value={`Hotel: ${selectedCoupon.hotelName}, Location: ${selectedCoupon.location}, City: ${selectedCoupon.city}, Coupon Code: ${selectedCoupon.couponCode}`}
                      size={120}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      style={{ height: '100px' }}
                      level={"L"}
                    />
                  </Row>
                </Col>
                {/* <Row>
              <Col className="d-flex justify-content-center">
                <p className="text-muted">Scan to Redeem</p>
              </Col>
            </Row> */}

                <Row className=" justify-content-center">
                  <Col md={8} className=" justify-content-center">
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleRedeem();
                    }}>
                      <Form.Group controlId="guestnumber">
                        {/* <Form.Label>Number of Guests:</Form.Label> */}
                        <Form.Control
                          style={{ marginTop: '10px' }}
                          type="number"
                          placeholder="Enter number of guests"
                          value={noOfGuest}
                          onChange={(e) => setNoOfGuest(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="mobileNumber">
                        {/* <Form.Label>Contact Number:</Form.Label> */}
                        <Form.Control
                          style={{ marginTop: '10px' }}
                          type="text"
                          placeholder="Enter mobile number"
                          value={mobileNumber}
                          onChange={(e) => {
                            // Restrict to 10 digits
                            if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                              setMobileNumber(e.target.value);
                            }
                          }}
                          maxLength="10" // Ensure input length is max 10 digits
                          pattern="[0-9]{10}" // Regex for exactly 10 digits
                          required
                        />
                      </Form.Group>

                      <Button className='p-1 px-2 mt-2 justify-content-center' style={{ background: '#6C7594', border: 'none', backgroundColor: '#EF4C0B' }} variant="primary" type="submit" disabled={isRedeeming}>
                        {isRedeeming ? 'Redeeming...' : 'Redeem Now'}

                      </Button>
                    </Form>
                  </Col>
                </Row>

                {message && <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <p className="text-danger">{message}</p>
                  </Col>
                </Row>}
              </Container>

            </>
          )}
          
        </Modal.Body>
      </Modal>

      <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>Login or Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='pb-4'>To claim this offer, please log in or purchase a membership.</p>
          <div className='d-flex '>
            <Button
              variant="primary"
              size="sm" // Makes the button small
              onClick={() => navigate('/signin')} // Navigate to /signin
              style={{ padding: '5 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'atuo', marginRight: '20px' }} // Smaller width
            >
              One Time Registration
            </Button>
            <Button
              variant="primary"
              size="sm" // Makes the button small
              onClick={() => navigate('/signupform')} // Navigate to /signin
              style={{ padding: '5px 15px', backgroundColor: '#EF4C0B', border: 'none', width: 'auto', marginLeftLeft: '10px' }} // Smaller width
            >
              Member Login
            </Button>
          </div>
          
        </Modal.Body>
       
      </Modal>
      <Footer />
      <ToastContainer />
    </>
  );
}
