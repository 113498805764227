


import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button,Navbar, Nav, } from 'react-bootstrap';
import { auth } from '../firebase.config'; // Firebase configuration
import { RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'; // Firebase Auth imports
import { useNavigate } from 'react-router-dom'; // For navigation
import '../CSS/SignUp.css';
import Header from './Header'; // Import the new Header component
import axios from 'axios';
import Footer from './Footer';
import logoimage from '../assets/DF-LOGO1 (2).png';
import Illustration from '../assets/Illustration.png'
import MyCards from './MyCards';
import UserHeader from './UserHeader';


export default function SignUp() {


  



  const [memberIdState, setMemberIdState] = useState(''); // State to handle the member ID input

  const handleMemberIdChange = (e) => {
    const upperCaseMemberId = e.target.value.toUpperCase(); // Convert to uppercase
    setMemberIdState(upperCaseMemberId); // Update state with uppercase value
  };

  const [showForm, setShowForm] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default to India
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false); // Initial state is false
  const otpRef = useRef(null); // Ref for OTP input
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const timer = setTimeout(() => {
        setHideImage(true);
        setShowForm(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && verificationId) {
      verifyOtp();
    }
  }, [otp]);

  useEffect(() => {
    if (phoneNumber.length === 10 && !otpSent) { // Ensure OTP is sent only once
      sendOtp();
      if (otpRef.current) {
        otpRef.current.focus(); // Focus OTP field when OTP is sent
      }
    }
  }, [phoneNumber]);

  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            console.log('reCAPTCHA solved', response);
          },
        },
        auth
      );
    } else {
      console.log('reCAPTCHA already initialized');
    }
  };

  const sendOtp = async () => {
    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      setOtpSent(true); // Update state after sending OTP
      setError('');
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError('Failed to send OTP. Try again later.');
      window.recaptchaVerifier.reset(); // Reset recaptcha on failure
    }
  };

  const verifyOtp = async () => {
    if (!otp || !verificationId) {
      setError('Please enter the OTP.');
      return;
    }

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);
      setError('OTP verified successfully.');
      // Navigate to payment page or any other page on success
      // navigate('/pay');
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError('Invalid OTP. Please enter the correct OTP.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const memberId = e.target.memberId.value;
    const email = e.target.email.value;
    const dob = e.target.dob.value;
    const address = e.target.address.value;
    const phoneNumber = e.target.phoneNumber.value.replace(/\D/g, ''); // Remove non-digit characters
  
    try {
      const response = await axios.post('https://discountfeast.co.in/newmember/register', { name, memberId, email, dob, address, phoneNumber });
      console.log(response.data);

      

      // Navigate to the /offercards page after successful registration
      navigate('/');
    } catch (err) {
      console.error('Error registering member:', err);
      setError('Failed to register member. Please try again.');
    }
  };
  

  return (
    <>
      <Col lg={12}>
          <UserHeader/>
      </Col>
      <Container className="d-grid align-items-center justify-content-center pt-4 w-100" style={{ maxWidth: '100%' }}>
        <Row className="w-100" style={{ marginLeft: '0px' }}>
          {/* Left Column with Image
          <Col lg={4} md={4} sm={4} xs={12} className={`p-0 dineinimage-wrapper ${hideImage ? 'hide' : ''}`}>
            <div className="dineinimage"></div>
          </Col> */}

          {/* Right Column with Form */}

          <Col lg={12} md={12} sm={12} xs={12} className={`form d-flex align-items-center justify-content-center bgimage1 p-3 ${showForm ? 'show' : ''}`}>
            <div className=" w-100">
              {/* Form Section */}
              <Col Col lg={12} md={8} sm={8} xs={12} className='d-flex justify-content-center'>
              <div className="form-container pt-4 justify-content-center" >
                <h2 className="signuph2 text-center">Sign up to Discount Feast</h2>

                <Form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <Row className='d-flex justify-content-center'>
                    <Col lg={4} md={6} sm={6} xs={6}  >
                      <Form.Group controlId="name" className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6}>
                    <Form.Group controlId="memberId" className="mb-3">
      <Form.Label>Member ID</Form.Label>
      <Form.Control
        type="text"
        placeholder="Member ID"
        name="memberId"
        value={memberIdState} // Use the state value
        onChange={handleMemberIdChange} // Event handler to convert to uppercase
        required
      />
    </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <Col lg={4} md={6} sm={6} xs={6}>
                      <Form.Group controlId="email" className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4} md={6} sm={6} xs={6}>
                      <Form.Group controlId="dob" className="mb-3">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                        type="date"
                        placeholder="dd/mm/yyyy"
                          // type="date"
                          // placeholder="MM / DD / YYYY"
                          name="dob"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <Col lg={8} sm={12} md={12} xs={12}>
                      <Form.Group controlId="address" className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          placeholder="Address"
                          name="address"
                          required
                        />
                      </Form.Group>
                    </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'> 
                    <Col lg={4} sm={8} md={8} xs={12}>
                      <Form.Group controlId="phoneNumber" className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <div>
                          <Form.Control
                            type="text"
                            placeholder="1234567890"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            name="phoneNumber"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    {/* OTP Section - Initially displayed and enabled/disabled based on `otpSent` state */}

                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Form.Group controlId="otp" className="mb-3">
                        <Form.Label>Enter OTP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          maxLength={6}
                          required
                          ref={otpRef} // Attach ref to OTP input
                          disabled={!otpSent} // Disable OTP field if OTP not sent
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                Creating an account means you’re okay
                                with our <a href="" style={{ textDecoration: 'none' }}>Terms of Service, Privacy Policy,</a>
                                and our default <a href="" style={{ textDecoration: 'none' }}>Notification Settings.</a>
                              </span>}
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                    <Col lg={4} sm={12} md={12} xs={12}>
                      <Form>
                        {['checkbox'].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type={type}
                              id={`default-${type}`}
                              label={<span>
                                I would like to receive communication
                                & updates on <a href="" style={{ textDecoration: 'none' }}>WhatsApp </a> with the number I have provided.
                              </span>}
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>

                  <div id="recaptcha-container" className="mb-3"></div>
                  {error && <p className="text-danger text-center">{error}</p>}
                  <Row className='d-flex justify-content-center'>
                    <Col lg={3} sm={12} md={12} xs={12}>
                      <Button type="submit" className="w-100" style={{backgroundColor:'#EF4C0B', color:'white', border:'none' }} onClick={MyCards}>
                        Register
                      </Button>
                    </Col>
                    <Col lg={3} sm={12} md={12} xs={12}>
                      <p className='d-flex'>Already a member? <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '0px 0px',
                borderRadius: 'none',
                color:'#0D6EFD',
                backgroundColor:'transparent',
                border:'none'
                
              }}
              onClick={() => navigate('/signupform')}
            >
              Login
            </Button>
                      </p>
                    </Col>
                    {/* <Col lg={3} sm={12} md={12} xs={12}>
                    <p className='d-flex'>Request for <Button
              variant="secondary"
              className="mx-2"
              style={{
                fontSize: '14px',
                padding: '0px 0px',
                borderRadius: 'none',
                color:'#0D6EFD',
                backgroundColor:'transparent',
                border:'none'
                
              }}
              onClick={() => navigate('/requestmembership')}
            >
             Membership
            </Button>
                      </p>
                    </Col> */}
                  </Row>
                </Form>
              </div>
              </Col>
            </div>
            </Col>
        </Row>
       
      </Container>
      <Col lg={12} style={{padding:'0px'}}>
      <Footer/>
      </Col>
    </>
  );
}
